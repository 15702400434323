<template>

    <span class="d-flex flex-row">

        <!--Expiration Time-->

        <b-input-group v-if="type === 'caseRequest' || (type === 'reapprove' && (request.status === 'Expired' || request.status === 'Denied') && !request.caseRequestId)"
                       class="input-group-sm" style="font-size: 15px; width: fit-content; margin-right: 2px">
            <b-form-input v-model="expiration" size="sm" style="width: 35px; height: 22px" placeholder="00"/>
            <b-input-group-append class="input-group-sm" style="height: 22px">
                <b-input-group-text style="line-height: 1">Days</b-input-group-text>
            </b-input-group-append>
        </b-input-group>

        <!--Approve Button-->
        <b-button variant="primary" size="sm" v-if="type !== 'reapprove' || (type === 'reapprove' && (request.status === 'Expired' || request.status === 'Denied') && !request.caseRequestId)"
                  @click="approveRequest" :disabled="(expiration === '' || expiration <= 0) && type !== 'userRequest'" style="height: 20px; transform: translateY(1px); line-height: .9">
            Approve
        </b-button>&nbsp;

        <!--Deny Button-->
        <b-button variant="danger" size="sm" v-if="type !== 'reapprove'" @click="denyRequest" style="height: 20px; transform: translateY(1px); line-height: .9">
            Deny
        </b-button>

    </span>

</template>

<script>
import digitalevidence from '@/services/digital-evidence';

export default {
    name: "ApproveDenyPanel",

    props: {
        request: {type: Object},
        type: {type: String}
    },

    data() {
        return {
            expiration: ''
        }
    },

    methods: {
        approveCaseRequest() {
            digitalevidence.PostApproveCaseRequest(this.request, {expiration: this.expiration * 86400}).then(() => {
                this.expiration = '';
                this.$mToast({
                    title: 'Success',
                    text: 'Case Request Successfully Approved',
                    style: 'success'
                });
                this.$emit('updatePage');
            }).catch(() => {
                this.$mToast({
                    title: ' Error',
                    text: 'Error Approving Case Request',
                    style: 'error'
                });
            });
        },
        approveUserRequest() {
            digitalevidence.PostApproveUserRequest(this.request).then(() => {
                this.expiration = '';
                this.$mToast({
                    title: 'Success',
                    text: 'User Request Successfully Approved',
                    style: 'success'
                });
                this.$emit('updatePage');
            }).catch(() => {
                this.$mToast({
                    title: ' Error',
                    text: 'Error Approving User Request',
                    style: 'error'
                });
            });
        },
        approveRequest() {
            if (this.type === 'caseRequest' || this.type === 'reapprove') {
                this.approveCaseRequest();
            } else if (this.type === 'userRequest') {
                this.approveUserRequest();
            } else if (this.type === 'reapprove' && (this.request.status === 'Expired' || this.request.status === 'Denied')) {
                this.approveCaseRequest();
            }
        },
        denyRequest() {
            if (this.type === 'caseRequest') {
                digitalevidence.denyCaseRequest(this.request._id).then(response => {
                    this.$mToast({
                        title: 'Success',
                        text: 'Case Request Successfully Denied',
                        style: 'success'
                    });
                    this.$emit('updatePage');
                }).catch(() => {
                    this.$mToast({
                        title: ' Error',
                        text: 'Error Denying Case Request',
                        style: 'error'
                    });
                });
            } else if (this.type === 'userRequest') {
                digitalevidence.denyUserRequest(this.request._id).then(response => {
                    this.$mToast({
                        title: 'Success',
                        text: 'User Request Successfully Denied',
                        style: 'success'
                    });
                    this.$emit('updatePage');
                }).catch(() => {
                    this.$mToast({
                        title: ' Error',
                        text: 'Error Denying Case Request',
                        style: 'error'
                    });
                });
            }
        }
    }
}
</script>

<style scoped>

</style>