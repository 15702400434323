import axios from 'axios'
import Vue from 'vue'
function DigitalEvidence() {
    if(localStorage.getItem("customFields") !== null) {
        return axios.create({
            //baseURL: process.env.VUE_APP_DIGITALEVIDENCE,
            baseURL: "https://" + get_digital_evidence(),
            withCredentials: false,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + localStorage.mtopToken
            }
        });
    } else {
        /*this.$mToast({
            title: 'Error',
            text: "User account is missing entry for Digital Evidence, please contact support!",
            style: 'error'
        });*/
        return null;
    }
}

function get_digital_evidence() {
    return JSON.parse(localStorage.getItem("customFields")).digital_evidence;
}

export default {

    //Case request routes
    GetAllApprovedUserCaseRequests() {
        return new DigitalEvidence().get('/case/user/approved');
    },
    GetAllOtherUserCaseRequests(){
        return new DigitalEvidence().get("/case/user");
    },
    PostApproveCaseRequest(request, body) {
        return new DigitalEvidence().post('/case/approve/' + request._id, body);
    },
    GetAllCaseRequests() {
        return new DigitalEvidence().get('/case');
    },
    PostCaseRequest(request) {
        return new DigitalEvidence().post('/case' , request);
    },

    //User request routes
    PostApproveUserRequest(request) {
        return new DigitalEvidence().post('/user/request/approve/' + request._id);
    },
    GetAllUserCaseRequests(user){
        return new DigitalEvidence().get('/user/request/' + user._id);
    },
    GetListAllUserRequests() {
        return new DigitalEvidence().get('/user/request');
    },
    PostCreateAUserRequest(newUser, caseId) {
        return new DigitalEvidence().post('/user/request' , {newUser:newUser, caseRequestId:caseId });
    },

    // Evidence
    postToEvidence(evidenceObject) {
        return new DigitalEvidence().post('/evidence', evidenceObject);
    },
    getAllEvidence() {
        return new DigitalEvidence().get('/evidence');
    },
    updateEvidence(evidenceId, evidence) {
        return new DigitalEvidence().put('/evidence/' + evidenceId, evidence);
    },
    downloadEvidence(fileId) {
        return new DigitalEvidence().get('/evidence/download', {responseType: 'blob', params: {
            fileId: fileId
        }});
    },
    uploadEvidence(formData) {
        return new DigitalEvidence().post('/evidence/upload', formData, {headers: {
                'Authorization': 'JWT ' + localStorage.mtopToken,
                'Content-Type': 'multipart/form-data',
            }});
    },
    changeCaseSensitive(evidenceId, boolean) {
        return new DigitalEvidence().put('/evidence/' + evidenceId, {caseSensitive: boolean});
    },

    // Supervisor View
    getUserRequestsWaiting() {
        return new DigitalEvidence().get('/user/request/waiting');
    },
    getUserRequestsOther() {
        return new DigitalEvidence().get('/user/request/other');
    },
    getCasesWaiting() {
        return new DigitalEvidence().get('/case/waiting');
    },
    getCasesOther() {
        return new DigitalEvidence().get('/case/other');
    },
    getCaseById(caseRequestId) {
        return new DigitalEvidence().get('/case/' + caseRequestId);
    },
    denyCaseRequest(caseRequestId) {
        return new DigitalEvidence().post('/case/deny/' + caseRequestId);
    },
    denyUserRequest(userRequestId) {
        return new DigitalEvidence().post('/user/request/deny/' + userRequestId);
    },

    // Audits
    getAllAudits() {
        return new DigitalEvidence().get('/audit');
    }

}