<template>

    <span>

        <!--Main Page-->
        <b-row>

            <!--Case Requests Waiting-->
            <b-col sm="6">
                <b-card header-tag="header" style="height: 500px;" no-body>
                    <span slot="header" style="width: 100%" class="d-flex align-items-center">
                        <strong>Pending Case Requests</strong>
                    </span>
                    <div v-if="casesWaiting.length > 0" style="height: 500px; overflow-y: auto">
                        <table style="width: 100%; table-layout: auto">
                            <template v-for="(report, index) in casesWaiting">
                                <b-card no-body :key="index" style="margin: 6px; background-color: #101010">

                                    <!--Cases Waiting-->
                                    <tr :key="index" style="min-height: 50px;">
                                        <span class="d-flex flex-row justify-content-between" style="align-items: center; height: 100%; flex-wrap: wrap; padding: 10px;">
                                            <span>
                                                Case {{report.caseNumber}} requested by {{report.requestedBy}} ({{fromNow(report.created_date)}})
                                            </span>
                                            <ApproveDenyPanel :request="report" :type="'caseRequest'" @updatePage="getInfo()" />
                                        </span>
                                    </tr>

                                </b-card>
                            </template>
                        </table>
                    </div>

                    <div v-else style="width: 100%; height: 100%;" class="d-flex justify-content-center align-items-center">
                        <span>no case requests</span>
                    </div>

                </b-card>
            </b-col>

            <!--User Requests Waiting-->
            <b-col sm="6">
                <b-card header-tag="header" style="height: 500px; overflow-y: auto;" no-body>
                    <span slot="header" style="width: 100%" class="d-flex align-items-center">
                        <strong>Pending User Requests</strong>
                    </span>


                    <div v-if="userRequestsWaiting.length > 0" style="height: 500px; overflow-y: auto">
                        <table v-if="userRequestsWaiting.length > 0" style="width: 100%; table-layout: auto;">
                            <template v-for="(report, index) in userRequestsWaiting">
                                <b-card no-body :key="index" style="margin: 6px; background-color: #101010">

                                    <!--Cases Waiting-->
                                    <tr :key="index" style="min-height: 50px;">
                                        <span class="d-flex flex-row justify-content-between" style="align-items: center; height: 100%; flex-wrap: wrap; padding: 10px;">
                                            <span v-if="report.requestedBy === report.newUser.username">
                                                User {{report.requestedBy}} has requested to be added to Case {{report.caseNumber}} ({{fromNow(report.created_date)}})
                                            </span>
                                            <span v-else>
                                                User {{report.requestedBy}} has requested to add {{report.newUser.username}} to Case {{report.caseNumber}} ({{fromNow(report.created_date)}})
                                            </span>
                                            <ApproveDenyPanel :request="report" :type="'userRequest'" @updatePage="getInfo()" />
                                        </span>
                                    </tr>

                                </b-card>
                            </template>
                        </table>
                    </div>

                    <div v-else style="width: 100%; height: 100%;" class="d-flex justify-content-center align-items-center">
                        <span>no user requests</span>
                    </div>

                </b-card>

            </b-col>

            <!--Case Requests Other-->
            <b-col sm="12">


                <b-card header-tag="header" no-body>

                    <span slot="header" class="d-flex flex-row justify-content-between align-items-center">
                        <strong>Case Requests</strong>
                        <b-input size="sm" v-model="caseSearchText" type="text" placeholder="Search by Case Number" style="width: 200px;"/>
                    </span>


                    <!--Table-->
                    <table v-if="displayedCaseRequests.length > 0" style="width: 100%; table-layout: auto;">

                        <!--Column Names-->
                        <b-card no-body style="background: #000000; margin: 6px 6px 0 6px; font-size: 16px">
                            <b-row style="margin: 3px">

                                <b-col style="text-align: center; padding: 0; margin: 0; text-align: left">
                                    <strong>Requested By</strong>
                                </b-col>
                                <b-col style="text-align: center; padding: 0; margin: 0;">
                                    <strong>Created Date</strong>
                                </b-col>
                                <b-col style="text-align: center; padding: 0; margin: 0;">
                                    <strong>Case Number</strong>
                                </b-col>
                                <b-col style="text-align: center; padding: 0; margin: 0;">
                                    <strong>Status</strong>
                                </b-col>
                                <b-col style="text-align: center; padding: 0; margin: 0;">
                                    <strong>Expiration</strong>
                                </b-col>
                                <b-col style="text-align: center; padding: 0; margin: 0;">
                                    <strong>Users</strong>
                                </b-col>
                                <b-col style="text-align: center; padding: 0; margin: 0; text-align: right">
                                    <strong>Reapprove</strong>
                                </b-col>

                            </b-row>
                        </b-card>

                        <template v-for="(report, index) in displayedCaseRequests">

                            <b-card no-body :key="index" style="margin: 6px; background-color: #101010">

                                <tr :key="index" style="min-height: 50px;">

                                    <b-row style="text-align: center; margin: 0 auto; padding: 15px 0;">

                                        <!--User-->
                                        <b-col style="padding: 0; margin: 0 0 0 3px; display: flex; align-items: center; justify-content: left;">
                                            {{report.requestedBy}}
                                        </b-col>

                                        <!--Created Date-->
                                        <b-col style="padding: 0; margin: 0; display: flex; align-items: center; justify-content: center;">
                                            {{convertToDateTime(report.created_date)}}
                                        </b-col>

                                        <!--Case Number-->
                                        <b-col style="padding: 0; margin: 0; display: flex; align-items: center; justify-content: center;">
                                            {{report.caseNumber}}&nbsp;&nbsp;
                                        </b-col>

                                        <!--Status-->
                                        <b-col style="padding: 0; margin: 0; display: flex; align-items: center; justify-content: center;">
                                            {{report.status.toUpperCase()}}
                                            <fa-icon :icon="['fas', 'info']" style="padding: 0 5px 0 10px; cursor: pointer;"
                                                     @click="$bvModal.show('updates'), updatesReport = report" />
                                        </b-col>

                                        <!--Time Until Expiration-->
                                        <b-col style="padding: 0; margin: 0; display: flex; align-items: center; justify-content: center;">
                                            {{fromNow(report.expiration)}}
                                        </b-col>

                                        <!--Users-->
                                        <b-col style="padding: 0; margin: 0; display: flex; align-items: center; justify-content: center;">
                                            <fa-icon :icon="['fas', 'users']" size="lg" style="cursor: pointer;" @click="$bvModal.show('users'), usersReport = report"/>
                                        </b-col>

                                        <!--Reapprove-->
                                        <b-col style="padding: 0; margin: 0; display: flex; align-items: center; justify-content: right;margin-right: 3px">
                                            <div style="flex-grow: 1"/>
                                            <ApproveDenyPanel :request="report" :type="'reapprove'" @updatePage="getInfo()" />
                                            <span v-if="report.status === 'Approved'">
                                                <span>already approved</span>
                                            </span>
                                        </b-col>

                                    </b-row>

                                </tr>

                            </b-card>

                        </template>

                    </table>

                    <div v-else style="width: 100%; height: 100%; text-align: center;">

                        <span>no case requests found</span>

                    </div>

                    <!--Navigation Buttons-->
                    <nav style="text-align: center; margin-bottom: 6px">
                        <ul class="pagination" style="display: inline-block; margin: 0;">
                            <li style="display: inline-block;">
                                <b-button type="button"
                                          @click="casePage = (pageRange() - 6 > 0 ? pageRange() - 6 : casePage)"
                                          style="display: inline-block; border-bottom-right-radius: 0; border-top-right-radius: 0; background: #000000; border: 1px solid #000000"
                                          :style="pageRange() - 6 > 0 ? '' : 'color: grey'">
                                  <fa-icon :icon="['fas', 'angles-left']" size="lg" style="cursor: pointer;"/>
                                </b-button>
                            </li>
                            <li style="display: inline-block;">
                                <b-button type="button" @click="casePage - 1 > 0 ? casePage-- : ''" style="border-radius: 0; background: #090909; border: 1px solid #000000"
                                          :style="casePage - 1 > 0 ? '' : 'color: grey'">
                                    <fa-icon :icon="['fas', 'angle-left']" size="lg" style="cursor: pointer;"/>
                                </b-button>
                            </li>
                            <li style="display: inline-block">
                                <b-button type="button" class="page-link" v-for="(pageNumber, ix) in casePages.slice(pageRange() - 1 , pageRange() + 5)"
                                          :style="casePage === pageNumber ? 'color: grey' : ''"
                                          @click="casePage = pageNumber" :key="ix" style="display: inline-block; border-radius: 0"> {{pageNumber}}
                                </b-button>
                            </li>
                            <li style="display: inline-block;">
                                <b-button type="button" @click="casePage + 1 > casePages.length ? '' : casePage++" style="border-radius: 0; background: #090909; border: 1px solid #000000"
                                          :style="casePage + 1 > casePages.length ? 'color: grey' : ''">
                                    <fa-icon :icon="['fas', 'angle-right']" size="lg" style="cursor: pointer;"/>
                                </b-button>
                            </li>
                            <li style="display: inline-block;">
                                <b-button type="button"
                                          @click="casePage = pageRange() + 6 > casePages.length || !casePages[pageRange()+ 5] ? casePage : pageRange() + 6"
                                          style="display: inline-block; border-bottom-left-radius: 0; border-top-left-radius: 0; background: #000000; border: 1px solid #000000"
                                          :style="pageRange() + 6 > casePages.length || !casePages[pageRange() + 5] ? 'color: grey' : ''">
                                  <fa-icon :icon="['fas', 'angles-right']" size="lg" style="cursor: pointer;"/>
                                </b-button>
                            </li>
                        </ul>
                    </nav>

                </b-card>

            </b-col>

            <!--User Requests Other-->
            <b-col sm="12">
                <b-card header-tag="header" no-body>

                    <span slot="header" class="d-flex flex-row justify-content-between align-items-center">
                        <strong>User Requests</strong>
                        <b-input size="sm" v-model="userSearchText" type="text" placeholder="Search by Case Number" style="width: 200px;"/>
                    </span>

                    <!--Table-->
                    <table v-if="displayedUserRequests.length > 0" style="width: 100%; table-layout: auto;">

                        <!--Column Names-->
                        <b-card no-body style="background: #000000; margin: 6px 6px 0 6px; font-size: 16px">
                            <b-row style="margin: 3px">

                                <b-col style="text-align: center; padding: 0; margin: 0; text-align: left">
                                    <strong>Requested By</strong>
                                </b-col>
                                <b-col style="text-align: center; padding: 0; margin: 0;">
                                    <strong>Created Date</strong>
                                </b-col>
                                <b-col style="text-align: center; padding: 0; margin: 0;">
                                    <strong>Case Number</strong>
                                </b-col>
                                <b-col style="text-align: center; padding: 0; margin: 0;">
                                    <strong>Status</strong>
                                </b-col>
                                <b-col style="text-align: center; padding: 0; margin: 0; text-align: right">
                                    <strong>Requested to Add</strong>
                                </b-col>

                            </b-row>
                        </b-card>

                        <template v-for="(report, index) in displayedUserRequests">

                            <b-card no-body :key="index" style="margin: 6px; background-color: #101010">

                                <tr :key="index" style="min-height: 50px;">

                                    <b-row style="text-align: center; margin: 0 auto; padding: 15px 0;">

                                        <!--User-->
                                        <b-col style="padding: 0; margin: 0 0 0 3px; display: flex; align-items: center; justify-content: left;">
                                            {{report.requestedBy}}
                                        </b-col>

                                        <!--Created Date-->
                                        <b-col style="padding: 0; margin: 0; display: flex; align-items: center; justify-content: center;">
                                            {{convertToDateTime(report.created_date)}}
                                        </b-col>

                                        <!--Case Number-->
                                        <b-col style="padding: 0; margin: 0; display: flex; align-items: center; justify-content: center;">
                                            {{report.caseNumber}}
                                        </b-col>

                                        <!--Status-->
                                        <b-col style="padding: 0; margin: 0; display: flex; align-items: center; justify-content: center;">
                                            {{report.status.toUpperCase()}}
                                            <fa-icon :icon="['fas', 'info']" style="padding: 0 5px 0 10px; cursor: pointer;"
                                                     @click="$bvModal.show('updates'), updatesReport = report" />
                                        </b-col>

                                        <!--Requested-->
                                        <b-col style="padding: 0; margin: 0 3px 0 0; display: flex; align-items: center; justify-content: center;">
                                            <div style="flex-grow: 1"/>
                                            {{report.newUser.username}}
                                        </b-col>

                                    </b-row>

                                </tr>

                            </b-card>

                        </template>

                    </table>

                    <div v-else style="width: 100%; height: 100%; text-align: center;">

                        <span>no user requests found</span>

                    </div>

                  <!--Navigation Buttons-->
                    <nav style="text-align: center; margin-bottom: 6px">
                        <ul class="pagination" style="display: inline-block; margin: 0;">
                            <li style="display: inline-block;">
                                <b-button type="button"
                                          @click="userPage = (userPageRange() - 6 > 0 ? userPageRange() - 6 : userPage)"
                                          style="display: inline-block; border-bottom-right-radius: 0; border-top-right-radius: 0; background: rgb(0,0,0); border: 1px solid #000000"
                                          :style="userPageRange() - 6 > 0 ? '' : 'color: grey'">
                                  <fa-icon :icon="['fas', 'angles-left']" size="lg" style="cursor: pointer;"/>
                                </b-button>
                            </li>
                            <li style="display: inline-block;">
                                <b-button type="button" @click="userPage - 1 > 0 ? userPage-- : ''" style="border-radius: 0; background: #090909; border: 1px solid #000000"
                                          :style="userPage - 1 > 0 ? '' : 'color: grey'">
                                  <fa-icon :icon="['fas', 'angle-left']" size="lg" style="cursor: pointer;"/>
                                </b-button>
                            </li>
                            <li style="display: inline-block">
                                <b-button type="button" class="page-link" v-for="(pageNumber, ix) in userPages.slice(userPageRange() - 1 , userPageRange() + 5)"
                                          :style="userPage === pageNumber ? 'color: grey' : ''"
                                          @click="userPage = pageNumber" :key="ix" style="display: inline-block; border-radius: 0"> {{pageNumber}}
                                </b-button>
                            </li>
                            <li style="display: inline-block;">
                                <b-button type="button" @click="userPage + 1 > userPages.length ? '' : userPage++" style="border-radius: 0; background: #090909; border: 1px solid #000000"
                                          :style="userPage + 1 > userPages.length ? 'color: grey' : ''">
                                  <fa-icon :icon="['fas', 'angle-right']" size="lg" style="cursor: pointer;"/>
                                </b-button>
                            </li>
                            <li style="display: inline-block;">
                                <b-button type="button"
                                          @click="userPage = userPageRange() + 6 > userPages.length || !userPage[userPageRange()+ 5] ? userPage : userPageRange() + 6"
                                          style="display: inline-block; border-bottom-left-radius: 0; border-top-left-radius: 0; background: #000000; border: 1px solid #000000"
                                          :style="userPageRange() + 6 > userPages.length || !userPages[userPageRange() + 5] ? 'color: grey' : ''">
                                  <fa-icon :icon="['fas', 'angles-right']" size="lg" style="cursor: pointer;"/>
                                </b-button>
                            </li>
                        </ul>
                    </nav>

                </b-card>
            </b-col>

        </b-row>

        <!--Updates Modal-->
        <b-modal id="updates" :title="'Case: ' + updatesReport.caseNumber" style="max-height: calc(100vh - 300px);">

            <table v-if="updatesReport.updates && updatesReport.updates.length > 0" style="width: 100%; table-layout: auto;">

                <b-row style="margin: 0 0 10px 0;">

                    <b-col sm="4" style="margin: auto; text-align: center;">
                        <strong>Status</strong>
                    </b-col>
                    <b-col sm="4" style="margin: auto; text-align: center;">
                        <strong>Updated</strong>
                    </b-col>
                    <b-col sm="4" style="margin: auto; text-align: center;">
                        <strong>Updated By</strong>
                    </b-col>

                </b-row>

                <template v-for="(update, index) in updatesReport.updates">

                    <b-card no-body bg-variant="dark" :key="index" stle="">

                        <tr :key="index" style="height: 50px;">

                            <b-row style="margin: 0 auto; height: 100%;">

                                <b-col sm="4" style="margin: auto; text-align: center;">
                                    {{update.status.toUpperCase()}}
                                </b-col>

                                <b-col sm="4" style="margin: auto; text-align: center;">
                                    {{fromNow(update.updatedAt)}}
                                </b-col>

                                <b-col sm="4" style="margin: auto; text-align: center;">
                                    {{update.updatedBy}}
                                </b-col>

                            </b-row>
                        </tr>

                    </b-card>

                </template>

            </table>

            <div v-else style="width: 100%; height: 100%; text-align: center;">

                <span>no updates</span>

            </div>

        </b-modal>

        <!--Users Modal-->
        <b-modal id="users" :title="'Users for Case ' + usersReport.caseNumber" style="max-height: calc(100vh - 300px);">

            <ul v-if="usersReport.users && usersReport.users.length > 0">

                <li v-for="(user, index) in usersReport.users" :key="index">{{user.username}}</li>

            </ul>

        </b-modal>

    </span>

</template>

<script>
import digitalevidence from '../../../services/digital-evidence';
import ApproveDenyPanel from "@/views/expansion_modules/digital_evidence/ApproveDenyPanel";
import * as moment from "moment";

export default {
    name: "SupervisorView",

    components: {
        ApproveDenyPanel
    },

    created() {
        this.getInfo();
        this.timer = setInterval(this.getInfo, 30000);
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },

    computed: {
        displayedCaseRequests() {
            if (this.caseSearchText !== '') {
                // Filter cases based on search box
                let filteredCaseRequests = this.caseRequestsOther.filter(item => {
                    return item.caseNumber.toLowerCase().includes(this.caseSearchText.toLowerCase());
                });
                return this.paginateCaseRequests(filteredCaseRequests);
            } else {
                // if there is no search text, then all evidence is valid
                return this.paginateCaseRequests(this.caseRequestsOther);
            }
        },
        displayedUserRequests() {
            if (this.userSearchText !== '') {
                // Filter cases based on search box
                let filteredUserRequests = this.userRequestsOther.filter(item => {
                    return item.caseNumber.toLowerCase().includes(this.userSearchText.toLowerCase());
                });
                return this.paginateUserRequests(filteredUserRequests);
            } else {
                // if there is no search text, then all evidence is valid
                return this.paginateUserRequests(this.userRequestsOther);
            }
        }
    },

    data() {
        return {

            // these variables are used for sorting and pagination of the case requests table
            caseRequestsLength: 0, // used for pagination
            caseRequestsOther: [],
            casePage: 1, // current page for pagination
            casePages: [], // used for navbar - choosing which page to navigate to
            caseSearchText: '', // text in input search box,

            // these variables are used for sorting and pagination of the user requests table
            userRequestsLength: 0, // used for pagination
            userRequestsOther: [],
            userPage: 1, // current page for pagination
            userPages: [], // used for navbar - choosing which page to navigate to
            userSearchText: '', // text in input search box,

            casesWaiting: [],
            perPage: 4, // how many cases displayed per page for pagination
            timer: '', // used for auto-refreshing the data on the page
            updatesReport: {}, // what is displayed in the view updates modal
            usersReport: [], // report displayed in the view users modal
            userRequestsWaiting: []

        }
    },

    methods: {
        userPageRange(){
          return this.pageRange(this.userPage)
        },
        pageRange(page){
            let temp = (page ? page : this.casePage)
            if(temp <= 0 || (temp % 6 !== 0 && ((Math.floor(temp / 6) * 6) + 1) < 0)){
              return false;
            }else if(temp % 6 !== 0){
              return ((Math.floor(temp / 6) * 6) + 1);
            }else if(temp-5 < 0) {
              return false;
            }else{
              return (temp-5);
            }
        },
        convertToDateTime(date) {
            let dateTime = new Date(date).toString();
            return dateTime.substring(dateTime.indexOf(' ') + 1, dateTime.indexOf('G') - 1);
        },
        fromNow(date) {
            if (!date) {
                return 'N/A';
            }
            return moment(date).fromNow().toUpperCase();
        },
        getInfo() {

            // get pending user requests, sort requests, get case numbers
            digitalevidence.getUserRequestsWaiting().then(response => {
                this.userRequestsWaiting = response.data;
            }).then(() => {
                this.userRequestsWaiting.sort(function(a, b) {
                    let comparison = 0;
                    if (a.created_date < b.created_date) {
                        comparison = 1;
                    } else if (a.created_date > b.created_date) {
                        comparison = -1;
                    }
                    return comparison;
                });
            }).then(() => {
                for (let i = 0; i < this.userRequestsWaiting.length; i++) {
                    digitalevidence.getCaseById(this.userRequestsWaiting[i].caseRequestId).then(response => {
                        this.userRequestsWaiting[i].caseNumber = response.data.caseNumber;
                        this.$forceUpdate();
                    });
                }
            });

            // get other user requests, sort requests by created date, get case numbers
            digitalevidence.getUserRequestsOther().then(response => {
                this.userRequestsOther = response.data;
                this.userRequestsLength = response.data.length;
            }).then(() => {
                this.userRequestsOther.sort(function(a, b) {
                    let comparison = 0;
                    if (a.created_date < b.created_date) {
                        comparison = 1;
                    } else if (a.created_date > b.created_date) {
                        comparison = -1;
                    }
                    return comparison;
                });
            }).then(() => {
                for (let i = 0; i < this.userRequestsOther.length; i++) {
                    if (!this.userRequestsOther[i].expiration) {
                        digitalevidence.getCaseById(this.userRequestsOther[i].caseRequestId).then(response => {
                            this.userRequestsOther[i].caseNumber = response.data.caseNumber;
                            this.$forceUpdate();
                        });
                    }
                }
            });

            // get pending case requests, sort requests by created date
            digitalevidence.getCasesWaiting().then(response => {
                this.casesWaiting = response.data;
            }).then(() => {
                this.casesWaiting.sort(function(a, b) {
                    let comparison = 0;
                    if (a.created_date < b.created_date) {
                        comparison = 1;
                    } else if (a.created_date > b.created_date) {
                        comparison = -1;
                    }
                    return comparison;
                });
            });

            // get other case requests, sort requests by created date
            digitalevidence.getCasesOther().then(response => {
                this.caseRequestsOther = response.data;
                this.caseRequestsLength = response.data.length;
            }).then(() => {
                this.caseRequestsOther.sort(function(a, b) {
                    let comparison = 0;
                    if (a.created_date < b.created_date) {
                        comparison = 1;
                    } else if (a.created_date > b.created_date) {
                        comparison = -1;
                    }
                    return comparison;
                });
            });

        },
        paginateCaseRequests(caseRequests) {
            // paginates the displayed evidence
            this.caseRequestsLength = caseRequests.length;

            let page = this.casePage;
            let perPage = this.perPage;
            let from = (page * perPage) - perPage;
            let to = (page * perPage);
            return caseRequests.slice(from, to);
        },
        paginateUserRequests(userRequests) {
            // paginates the displayed evidence
            this.userRequestsLength = userRequests.length;

            let page = this.userPage;
            let perPage = this.perPage;
            let from = (page * perPage) - perPage;
            let to = (page * perPage);
            return userRequests.slice(from, to);
        },
        setCasePages() {
            // populates array of all possible page numbers for case requests table
            this.casePages = [];
            let numberOfPages = Math.ceil(this.caseRequestsLength / this.perPage);
            for (let index = 1; index <= numberOfPages; index++) {
                this.casePages.push(index);
            }
        },
        setUserPages() {
            // populates array of all possible page numbers for user requests table
            this.userPages = [];
            let numberOfPages = Math.ceil(this.userRequestsLength / this.perPage);
            for (let index = 1; index <= numberOfPages; index++) {
                this.userPages.push(index);
            }
        },
    },

    watch: {
        displayedCaseRequests() {
            // whenever displayed cases change, pagination pages do as well
            this.setCasePages();
        },
        displayedUserRequests() {
            // whenever displayed cases change, pagination pages do as well
            this.setUserPages();
        },
        caseSearchText() {
            // whenever search input changes, page is set to 1
            this.casePage = 1;
            this.$forceUpdate();
        },
        userSearchText() {
            // whenever search input changes, page is set to 1
            this.userPage = 1;
            this.$forceUpdate();
        },
        updatesReport() {
            this.updatesReport.updates = this.updatesReport.updates.sort(function(a, b) {
                let comparison = 0;
                if (a.updatedAt < b.updatedAt) {
                    comparison = 1;
                } else if (a.updatedAt > b.updatedAt) {
                    comparison = -1;
                }
                return comparison;
            })
        }
    }
}
</script>

<style>
.modal-content {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
}
</style>